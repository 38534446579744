import React, {useRef} from "react";
import logo from './logo.svg';
import fadl from './fa-download.svg';
import { useModal } from "./hooks/useModal";
import './App.css';
import Modal from "./components/Modal";
import { chooseTargetXlsxSheet } from "./libs/chooseTargetXlsxSheet";
import { convertSheet } from "./libs/convertSheet";
import {utils, writeFile} from "xlsx";
import autoFitColumns from "./libs/autoFitColumns";


function App() {
    const {modal, changeModal} = useModal({isVisible: false, mode: 'info', title: '', body: ''});

    const refFile = useRef<HTMLInputElement>(null);

    const onErrorAction = (err:Error) => {
        console.error(err, err.name, err.message)
        switch (err.name) {
            case 'NotFoundError' :
                changeModal({isVisible: true, mode: 'error', title: '読込エラー ファイルなし', body: ['読込エラーです。ファイルが見つかりません。',<br />,'再度ファイルを読み込み直してください。']});
                break;
            case 'NotReadableError' :
                changeModal({isVisible: true, mode: 'error', title: '読込エラー アクセス権限なし', body: ['読込エラーです。アクセス権限がありません。',<br />,'再度ファイルを読み込み直してください。']});
                break;
            case 'TypeError' :
                changeModal({isVisible: true, mode: 'error', title: '読込エラー 形式エラー', body: ['読込エラーです。適切なエクセルファイルではありません。',<br />,'適切なエクセルファイルを読み込んでください。']});
                break;
            default :
                switch (err.message) {
                    case '変換エラー パターン特定失敗' :
                        changeModal({isVisible: true, mode: 'error', title: '変換エラー パターン特定失敗', body: ['変換エラーです。変換パターンの特定ができませんでした。', <br />, 'シート名が 1-,2-,3-,4-,5- になっているか確認してください。']});
                        break;
                    case '変換エラー パターン01' :
                        changeModal({isVisible: true, mode: 'error', title: '変換エラー パターン01', body: ['変換エラーです。採番に想定されていない文字が含まれています。', <br />, '使用できる文字は次の通りです。1,2,3,4,5,6,7,8,9,10,C',]});
                        break;
                    case '変換エラー パターン02' :
                        changeModal({isVisible: true, mode: 'error', title: '変換エラー パターン02', body: ['変換エラーです。採番に想定されていない文字が含まれています。', <br />, '使用できる文字は次の通りです。1,2,3,4,5,6,7,8,9,10,R']});
                        break;
                    case '変換エラー パターン03' :
                        changeModal({isVisible: true, mode: 'error', title: '変換エラー パターン03', body: ['変換エラーです。採番に想定されていない文字が含まれています。', <br />, '使用できる文字は次の通りです。1,2,3,4,5,6,7,8,9,10,R']});
                        break;
                    case '変換エラー パターン04' :
                        changeModal({isVisible: true, mode: 'error', title: '変換エラー パターン04', body: ['変換エラーです。採番に想定されていない文字が含まれています。', <br />, '使用できる文字は次の通りです。1,2,3,R']});
                        break;
                    case '変換エラー パターン04 invailed key' :
                        changeModal({isVisible: true, mode: 'error', title: '変換エラー パターン04 invailed key', body: ['変換エラーです。データ列名に想定されていない文字が含まれています。', <br />, 'データ列名に使用できる文字は次の通りです。B,C,D,E']});
                        break;
                    case '変換エラー パターン05' :
                        changeModal({isVisible: true, mode: 'error', title: '変換エラー パターン05', body: ['変換エラーです。採番に想定されていない文字が含まれています。', <br />, '使用できる文字は次の通りです。1,2,3,4,5,6,7,8,9,10.11,12,13,14,15,16,17,18,19,20,R']});
                        break;
                    case '変換エラー パターン05 overflow' :
                        changeModal({isVisible: true, mode: 'error', title: '変換エラー パターン05 overflow', body: ['変換エラーです。テンプレートで指定された長さよりも大きな数で採番されています。', <br />, 'テンプレートで指定された長さと採番が正しいか確認してください。']});
                        break;
                    default :
                        changeModal({isVisible: true, mode: 'error', title: '未知エラー', body: ['予期せぬエラーです。開発者に以下のエラーメッセージを伝えてください。',<br />,`[${err.name}] ${err.message}`]});
                        break;
                }
                break;
        }
        if(refFile.current) refFile.current.value = '';
        return;
    }

    const handleChangeFile = async () => {
        try {
            // Excelファイルが選択されていない場合
            const files = refFile.current?.files;
            if(!files || !files[0]) {
                return;
            }
            const {sheetName} = await chooseTargetXlsxSheet(files[0]);
            changeModal({isVisible: true, mode:'info', title:'変換対象シート', body: '変換対象シートは '+sheetName+'です。'})
        } catch(err:any) {
            onErrorAction(err);
            return;
        }
    }

    const parseXlsx = async() => {

        const newWorkbook = utils.book_new();
        let files;
        changeModal({isVisible: false, mode:modal.mode, title: modal.title, body:modal.body});

        // Excelファイルが選択されていない場合
        files = refFile.current?.files;
        if(!files || !files[0]) {
            changeModal({isVisible: true, mode:'error', title: '変換エラー', body:'Excelファイルが選択されていません。'});
            return;
        }
        try {
            const {sheetName, targetSheet} = await chooseTargetXlsxSheet(files[0]);
            if(targetSheet) {
                const {adjustSheet, resultSheet} = convertSheet(sheetName, targetSheet, changeModal);
                if(adjustSheet) {
                    utils.book_append_sheet(newWorkbook, autoFitColumns(adjustSheet), 'adjust');
                }
                utils.book_append_sheet(newWorkbook, autoFitColumns(resultSheet), 'result');
                utils.book_append_sheet(newWorkbook, targetSheet, sheetName);
            }
            writeFile(newWorkbook, files[0].name.replace(/\..*$/, '') +'__' + sheetName + '.xlsx');
            changeModal({isVisible: true, mode:'info', title:'変換完了', body: '変換が完了しました。'})
        } catch(err:any) {
            onErrorAction(err);
            return;
        }
    }

    return (
        <div className="App">
            <div>
                <h1>
                    <img src={logo} alt="cromaster" className={`mx-auto`} />
                </h1>
                <div className={`w-max mx-auto mt-[44px] pt-[10px] pb-[12px] px-[20px] text-[14px] rounded-[7px] bg-gray-300`}>
                    <input ref={refFile}
                           onClick={()=>{if(refFile.current) refFile.current.value = ''}}
                           onChange={handleChangeFile}
                           type="file"
                           accept=".xlsx"
                           className={` rounded-[3px] text-gray-900`} />
                    <p></p>
                </div>
                <div className={`mt-8 text-center`}>
                    <button
                        onClick={parseXlsx}
                        className={`w-max mx-auto pt-[10px] pb-[12px] px-[40px] rounded-[6px] border border-gray-300 bg-gray-100`}>
                        <img src={fadl} alt="ダウンロード"/>
                    </button>
                </div>
            </div>
            <div className={'flex gap-4 mt-8'} style={{display:'none'}}>
                <button className={`bg-gray-300 rounded-md p-2`} onClick={()=>{changeModal({isVisible: true, mode: 'info', title: 'インフォメッセージ', body: 'インフォです。インフォです。インフォです。インフォです。インフォです。インフォです。インフォです。インフォです。インフォです。インフォです。'})}}>インフォテスト</button>
                <button className={`bg-gray-300 rounded-md p-2`} onClick={()=>{changeModal({isVisible: true, mode: 'warn', title: '警告メッセージ', body: '警告です。警告です。警告です。警告です。警告です。警告です。警告です。警告です。警告です。警告です。警告です。警告です。警告です。警告です。'})}}>警告テスト</button>
                <button className={`bg-gray-300 rounded-md p-2`} onClick={()=>{changeModal({isVisible: true, mode: 'error', title: 'エラーメッセージ', body: 'エラーです。エラーです。エラーです。エラーです。エラーです。エラーです。エラーです。エラーです。エラーです。エラーです。エラーです。エラーです。エラーです。エラーです。'})}}>エラーテスト</button>
            </div>
            <Modal modal={modal} changeModal={changeModal}></Modal>
        </div>
  );
}



export default App;
