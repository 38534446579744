import {read, writeFile} from "xlsx";

/**
 * 変換対象とするシートを１つ返却する
 * アクティブシートがわかる場合はアクティブシート
 * そうでない場合は先頭のシートを対象にとる
 */
export const chooseTargetXlsxSheet = async function(file:File) {

    const buffer = await file.arrayBuffer();

    // workbookの型情報が提供されていないためany型
    const workbook:any = read(buffer);

    // 変換対象シートの添え字を調べる
    let targetSheetNum = 0;
    const wbview = workbook?.Workbook?.WBView;
    if(typeof wbview !== 'undefined') {
        targetSheetNum = wbview[0].activeTab;
    }

    const sheetName = workbook.Workbook.Sheets[targetSheetNum].name;
    const targetSheet = workbook.Sheets[sheetName];

    return {sheetName, targetSheet};
}
