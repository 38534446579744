import { Sheet } from "xlsx";
import { uniq } from "lodash";

export default function autoFitColumns(sheet:Sheet) {
    const rangeMatch = String(sheet['!ref']).match(/^([A-Z]+)(\d+):([A-Z]+)(\d+)$/);
    // console.log(Object.keys(sheet));
    const cols = uniq(Object.keys(sheet).filter((item) => {
        return /^([A-Z]+)(\d+)$/.test(item);
    }).map((str) => {
        // @ts-ignore
        return str.match(/^([A-Z]+)(\d+)$/)[1];
    }));

    const wscols = cols.map((colName) => {
        let colmax = 6;
        // @ts-ignore
        for(let rowNum = Number(rangeMatch[2]); rowNum <= Number(rangeMatch[4]); rowNum++) {
            const cell = sheet[colName + rowNum];
            if(cell && isFinite(cell.v.length)) {
                colmax = Math.max(colmax, 6 + cell.v.length * 1.2);
            }
        }
        return ({width: colmax});
    })

    // @debug
    //console.log(sheet, wscols);

    // 各列幅に設定
    sheet["!cols"] = wscols;
    return sheet;
}
