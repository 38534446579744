import {Sheet, utils} from "xlsx";

export const parsePattern02 = function(sheet:Sheet, changeModal:Function) {
    console.log(sheet);
    const json = utils.sheet_to_json(sheet, {raw: true});

    let head:any[] = [];
    let mode = 0;
    let modeMaxes:number[] = [];
    let error:any = null;
    let modeMax = 0;
    let previousMode = -1;

    const arr = json.reduce((acc:any[][], row:any) => {
        for (const [key, val] of Object.entries(row)) {
            // 1ケタの数字のとき、モードを変更する
            if (!val
                || ( key !== '採番1' && mode === 0 )
            ) {
                // valがないか、modeが0の時にスキップする
                continue;
            }
            if (key === '採番1' && typeof val === 'number' && isFinite(val) && (val <= 10)) {
                mode = val;
            } else if( key === '採番1' && (val === 'R')) {
                // valが設定されており、かつRの時
                acc.push(head); // 最後のheadを確定
                modeMaxes.push(modeMax);
                modeMax = 0;
                previousMode = -1;
                head = [];
                acc.push(['__Reset__', modeMaxes.length]); // レコード切り替え情報
            } else if(val && (mode > 0)) {
                // valが設定されており、かつmodeが0より大きいとき
                modeMax = (mode > modeMax)? mode: modeMax;
                if(previousMode >= mode) {
                    // 繰り返しか切り戻しが発生している
                    // console.log(mode, previousMode, head);
                    if(head.join("") !== "") acc.push(head); // 既存のheadを確定
                    head = head.slice(0,mode); // カラム番号(mode)より後ろを廃棄する
                }
                head[mode] = [mode,val];
                previousMode = mode;
                mode = 0;
            } else {
                console.log(mode, key, val);
                throw new Error('変換エラー パターン01');
            }
        }
        return acc;
    },[]);

    modeMaxes.push(modeMax);
    arr.push(head); // 最後のheadを確定

    if(error) return error;

    let offset = 0;
    let _modeMax = modeMaxes[0];

    const aoa = arr.reduce((acc, cur, index) => {
        //console.log(index, cur);
        let row = [];

        if(index === 0 || cur[0] === '__Reset__') {
            if(cur[0] === '__Reset__' && typeof cur[1] === 'number') {
                // 新しいレコード群に切り替わった場合
                acc.push([]);
                _modeMax = modeMaxes[cur[1]];
            }
            // カラム名の出力
            for(let i=1; i<=_modeMax; i++) {
                row.push(i+'N');
                row.push(i);
            }
            acc.push(row);
            row = [];
            offset = index;
            if(cur[0] === '__Reset__') offset++;
        }

        if(cur[0] !== '__Reset__') {
            for(let i=1; i<=_modeMax; i++) {
                row.push(index+1 - offset);
                row.push('');
            }

            const res = cur.reduce((row, cell) => {
                // 自動採番(1からスタート）
                row[cell[0]*2-2] = index+1 - offset;
                // 内容
                row[cell[0]*2-1] = cell[1];
                return row;
            }, row);
            acc.push(res);
        }

        return acc;
    }, []);

    console.log('pattern02', json, aoa);
    const parsedWorksheet = utils.aoa_to_sheet(aoa);

    return {adjustSheet:null, resultSheet:parsedWorksheet};
};
