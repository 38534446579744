import { parsePattern01 } from "./algorithm/parsePattern01";
import { parsePattern02 } from "./algorithm/parsePattern02";
import { parsePattern03 } from "./algorithm/parsePattern03";
import { parsePattern04 } from "./algorithm/parsePattern04";
import { parsePattern05 } from "./algorithm/parsePattern05";

const patternArray = [
    {regexp: /^1-/, callback: parsePattern01},
    {regexp: /^2-/, callback: parsePattern02},
    {regexp: /^3-/, callback: parsePattern03},
    {regexp: /^4-/, callback: parsePattern04},
    {regexp: /^5-/, callback: parsePattern05},
]

export const chooseAlgorithm = function(sheetName:string, changeModal:Function) {
    const parser = patternArray.reduce((acc:Function|null, cur) => {
        if(cur.regexp.test(sheetName)) {
            acc = cur.callback;
        };
        return acc;
    }, null );
    if(!parser) {
        throw new Error('変換エラー パターン特定失敗');
    }
    return parser;
}