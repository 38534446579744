import {Sheet, utils} from "xlsx";
import {AoaManipulator} from "./AoaManipulator";

const testNumbering = function (str:unknown):boolean {
    if(typeof str === 'string') {
        return /^採番/.test(str);
    } else {
        return false;
    }
}

const testDatacolumn = function(str:unknown):boolean {
    if(typeof str == 'string') {
        return /^[A-Z]$/.test(str);
    } else {
        return false;
    }
}

export const parsePattern04 = function(sheet:Sheet, changeModal:Function) {
    console.log(sheet);
    const json = utils.sheet_to_json(sheet, {raw: true});
    const aoaArray:any[] = [];
    let aoaManipulator:any;
    let previousMode = -1;
    let filledMap:Map<string,string>;
    let colNameSet:Set<string>;
    let mode = 0;

    // 設定を初期状態にする
    const reset = function() {
        previousMode = -1;
        filledMap = new Map();
        colNameSet = new Set();
        mode = 0;
        // 固定ヘッダーを追加
        colNameSet.add('1B');
        colNameSet.add('2B');
        aoaManipulator = new AoaManipulator();
    }

    const send = function() {
        const filledItr = filledMap[Symbol.iterator]();
        let filledItrItem = filledItr.next();
        let filledItrDone = filledItrItem.done;
        let filledItrValue = filledItrItem.value;
        while(!filledItrDone) {
            aoaManipulator.autoSet(filledItrValue[0], filledItrValue[1]);
            filledItrItem = filledItr.next()
            filledItrDone = filledItrItem.done;
            filledItrValue = filledItrItem.value;
        }
    }

    reset();

    json.filter((item:any)=>{return !!item['採番1']}).reduce((acc:any[][], row:any, idx) => {
        console.log(idx, row);

        // セルを処理していく
        for (const [key, val] of Object.entries(row)) {
            if(testNumbering(key)) {
                // 採番列
                if(typeof val !== 'undefined') {
                    // 採番列でvalがあるとき
                    if(typeof val === 'number' && isFinite(val) && (val <= 3) ) {
                        // modeとpreviousModeを更新
                        previousMode = (mode > 0)? mode: previousMode;
                        mode = val;

                        if(mode <= previousMode && previousMode > 0) {
                            // 切り戻しか繰り返しが発生しているとき

                            // 行を確定
                            send();

                            // fieldMapの後ろを破棄
                            let _tempMap  = filledMap;
                            filledMap = new Map();

                            if(mode === 2) {
                                const _1b = _tempMap.get('1B');
                                if(_1b) {
                                    filledMap.set('1B', _1b);
                                }
                            }
                            if(mode === 3) {
                                const [_1b, _2b] = [_tempMap.get('1B'), _tempMap.get('2B')];
                                if(_1b) filledMap.set('1B', _1b);
                                if(_2b) filledMap.set('2B', _2b);
                            }
                        }
                    } else if(val === 'R') {
                        // 採番列でRのとき

                        // 行を確定
                        send();

                        // resultを出力して空行を出力
                        aoaArray.push(aoaManipulator.generateResultAoa());
                        aoaArray.push(['']);

                        // 設定を戻す
                        reset();
                    } else {
                        // 採番列に不明な文字が入っているとき
                        console.warn('mode:' + mode, 'key:' + key, 'val:' + val);
                        throw new Error('変換エラー パターン04');
                    }
                } else {
                    // 採番列でvalがないとき
                    mode = 0;
                    console.log('not val', val);
                    continue;
                }
            } else if(testDatacolumn(key)) {
                // データ列
                if(typeof val !== 'undefined') {
                    // データ列でvalがあるとき

                    // カラム名を合成
                    const colName = mode + key;

                    if(mode > 0) {
                        // modeが0より大きい時
                        // filledMapに値を放り込んでいく
                        switch(mode) {
                            case 1:
                                if(key !== 'B') break;
                                filledMap.set(colName, String(val));
                                break;
                            case 2:
                                if(key !== 'B') break;
                                filledMap.set(colName, String(val));
                                break;
                            case 3:
                                // ヘッダーを追加
                                colNameSet.add(colName);
                                filledMap.set(colName, String(val));
                                break;
                        }
                    } else {
                        // modeが0以下の時
                        continue;
                    }
                } else {
                    // データ列でvalがないとき
                    continue;
                }
            } else {
                // 採番列でもデータ列でもない
                continue;
            }
        }
        return acc;
    },[]);

    // 行を確定
    send();

    aoaArray.push(aoaManipulator.generateResultAoa());
    const resultAoa = aoaArray.flat();

    const adjustSheet = null;
    const resultSheet = utils.aoa_to_sheet(resultAoa);

    return {adjustSheet, resultSheet};
};
