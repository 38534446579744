import {Sheet, utils} from "xlsx";
import {AoaManipulator} from "./AoaManipulator";

export const parsePattern01 = function(sheet:Sheet, changeModal:Function) {
    console.log(sheet);
    const json = utils.sheet_to_json(sheet, {raw: true});

    let mode = 0;
    let previousMode = -1;
    const aoaManipulator = new AoaManipulator();

    json.reduce((acc:any[][], row:any) => {
        for (const [key, val] of Object.entries(row)) {
            // 1ケタの数字のとき、モードを変更する
            if (!val
                || ( key !== '採番1' && mode === 0 )
            ) {
                // valがないか、modeが0の時にスキップする
                continue;
            }

            if (key === '採番1'
                && typeof val === 'number'
                && isFinite(val)
                && (val <= 10)
            ) {
                mode = val;
            } else if(key === '採番1' && val === 'C') {
                // Cが来た時
                mode = 333;
            } else if(val && mode === 333) {
                // Cの次
                if(previousMode >= 0) {
                    aoaManipulator.line('1','');
                } else {
                    // 一番最初の行
                    aoaManipulator.next('1','');
                }
                aoaManipulator.next('2','');
                aoaManipulator.next('3',String(val));
                aoaManipulator.next('4','');
                const _header = aoaManipulator.getHeader().values();
                [..._header].forEach((item) => {
                    if(item === '1' || item === '2' ||  item === '4' ) {
                        // do nothing
                    } else {
                        aoaManipulator.next(item,'');
                    }
                });
                // X座標を0に戻す
                aoaManipulator._coordinate[0] = 0;
                previousMode = 333;
                mode = 0;
            } else if(val && (mode > 0)) {
                if(mode <= previousMode) {
                    // 切り戻しか繰り返しが発生している
                    aoaManipulator.line(mode.toString(), String(val));
                    if(previousMode === 333) {
                        aoaManipulator.next('3', '__C__');
                    }
                } else {
                    aoaManipulator.next(mode.toString(), String(val));
                }
                previousMode = mode;
                mode = 0;
            } else {
                console.log(mode, key, val);
                throw new Error('変換エラー パターン01');
            }
        }
        return acc;
    },[]);

    const tableAoa = aoaManipulator.generateTableAoa();
    const resultAoa = aoaManipulator.generateResultAoa();

    console.log('pattern01', json, tableAoa);
    const adjustSheet = utils.aoa_to_sheet(tableAoa);
    const resultSheet = utils.aoa_to_sheet(resultAoa);

    return {adjustSheet, resultSheet};
};
