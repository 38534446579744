import { Sheet } from "xlsx";
import { chooseAlgorithm } from "./chooseAlgorithm";

/**
 * sheetを変換し、変換結果を返却する
 */
export const convertSheet = function(sheetName:string, sheet:Sheet, changeModal:Function) {
    const parser = chooseAlgorithm(sheetName, changeModal);
    let {adjustSheet, resultSheet} = parser(sheet, changeModal);
    return {adjustSheet, resultSheet};
}
