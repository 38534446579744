import { useState } from 'react';
import type { Modal } from '../types/Modal';

export const useModal = (init:Modal) => {
    const [modal, setModal] = useState(init);

    const changeModal = (modal:Modal) => {
        setModal((prevState) => {
           return modal;
        });
    }

    return {modal, changeModal}
}

export type typeModal = Modal;
export type useModalResult = ReturnType<typeof useModal>