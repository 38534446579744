import React, {useEffect, useRef} from "react";
import ReactDom from 'react-dom';
import {useModalResult} from "../hooks/useModal";
import {gsap} from "gsap";

const Modal = (props:useModalResult) => {
    const [modal, changeModal] = [props.modal, props.changeModal];
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(modal.isVisible) {
            gsap.fromTo(modalRef.current, {opacity:0, pointerEvents:'none'},{opacity:1, pointerEvents:'all'});
        } else {
            gsap.to(modalRef.current, {opacity:0, pointerEvents:'none'});
        }
        changeModal(modal);
    });

    const portal = document.querySelector('#portal');
    if(!portal) return null;

    return ReactDom.createPortal(
        <div ref={modalRef} className={`fixed top-8 left-0 right-0 b-auto max-w-[480px] w-full mx-auto opacity-0`}>
            <div className={`w-full bg-${modal.mode} text-gray-200 relative pl-[1.5em] pr-[3em] py-[1em] leading-[1.4] rounded-md border-gray-900 border`}>
                <p className={``}>{modal.body}</p>
                <button onClick={()=>{changeModal({...modal, isVisible: false})}}
                        className={`
                            absolute
                            top-[0.6em]
                            right-[1em]
                            text-lg
                `}>✕</button>
            </div>
        </div>,
        portal
    );
}

export default Modal;