import {Sheet, utils} from "xlsx";

export const parsePattern05 = function(sheet:Sheet, changeModal:Function) {
    console.log(sheet);
    const json = utils.sheet_to_json(sheet, {raw: true});

    let head:any[] = [];
    let mode = 0;
    let modeMaxes:number[] = [];
    let error:any = null;
    let modeMax = 0;
    let previousMode = -1;
    let isFrame = true;

    const arr = json.reduce((acc:any[][], row:any) => {
        for (const [key, val] of Object.entries(row)) {
            // 1ケタの数字のとき、モードを変更する
            if (!val || ( key !== '採番1' && mode === 0 ) ) {
                // valがないか、modeが0の時にスキップする
                mode = 0;
                continue;
            }
            if (key === '採番1' && typeof val === 'number' && isFinite(val) && (val <= 20)) {
                mode = val;
                if(isFrame && mode <= modeMax) {
                    // テンプレート部分の終了を検出
                    modeMaxes.push(modeMax);
                    isFrame = false;
                }
                if(isFrame) {
                    modeMax = Math.max(mode, modeMax);
                }
            } else if( key === '採番1' && (val === 'R')) {
                // valが設定されており、かつRの時
                acc.push(head); // 最後のheadを確定
                modeMax = 0;
                head = [];
                isFrame = true;
                acc.push(['__Reset__', modeMaxes.length]); // レコード切り替え情報
                console.log('on R', modeMaxes);
            } else if(key === 'データ1' && val && (mode > 0)) {
                if(previousMode >= mode) {
                    // 繰り返しか切り戻しが発生している
                    // console.log(mode, previousMode, head);
                    if(head.join("") !== "") acc.push(head); // 既存のheadを確定
                    head = head.slice(0,mode); // カラム番号(mode)より後ろを廃棄する
                }
                head[mode] = [mode,val];
                previousMode = mode;
                mode = 0;
            } else if(key === 'データ1' && (mode > 0)){
                // modeがあるのにデータ1にvalがないとき
                previousMode = mode;
                mode = 0;
                continue;
            } else if(key === 'データ1' || key ==='採番1'){
                console.log(mode, key, val);
                throw new Error('変換エラー パターン05');
            }
        }
        return acc;
    },[]);

    arr.push(head); // 最後のheadを確定
    console.log('last:', modeMaxes);

    if(error) return error;

    let offset = 0;
    let currentModeMax = 0;
    currentModeMax = modeMaxes[0];
    const aoa = arr.reduce((acc, cur, index) => {
        let row = [];
        if(index === 0 || cur[0] === '__Reset__') {
            if(cur[0] === '__Reset__' && typeof cur[1] === 'number') {
                // 新しいレコード群に切り替わった場合
                acc.push([]);
                currentModeMax = modeMaxes[cur[1]];
            }
            // カラム名の出力
            for(let i=1; i<=currentModeMax; i++) {
                row.push(i+'N');
                row.push(i);
            }
            acc.push(row);

            row = [];
            // フォーマット行の出力
            for(let i=1; i<=currentModeMax; i++) {
                row.push(1);
                row.push(null);
            }
            acc.push(row);
            row = [];

            offset = index;
            if(cur[0] === '__Reset__') offset++;
        }

        if(cur[0] !== '__Reset__') {
            for(let i=1; i<=currentModeMax; i++) {
                row.push(index+2 - offset);
                row.push(null);
            }

            const res = cur.reduce((row, cell) => {
                if(cell[0] > currentModeMax) {
                    throw new Error('変換エラー パターン05 overflow');
                }
                // 自動採番(2からスタート）
                row[cell[0]*2-2] = index+2 - offset;
                // 内容
                row[cell[0]*2-1] = cell[1];
                return row;
            }, row);
            acc.push(res);
        }
        console.log(currentModeMax);
        return acc;
    }, []);

    console.log('pattern05', json, aoa);
    const parsedWorksheet = utils.aoa_to_sheet(aoa);

    return {adjustSheet:null, resultSheet:parsedWorksheet};
};
