type Cell = {
    val: string|undefined;
    x: number;
    y: number;
}

type Line = Map<string,Cell|undefined>;

/**
 * ２次元配列操作クラス
 * エクセルシートを取り込んでから出力シートに加工するまでの共通処理を担う
 */
export class AoaManipulator {
    _coordinate: [number, number];
    _header: Set<string>;
    _table: Map<string,Cell|undefined>[];

    constructor(x = 0,y = 0) {
        this._coordinate = [x, y];
        this._header = new Set();
        this._table = [new Map()];
        return this;
    }

    /**
     * ヘッダーに表記するカラム名を追加する
     * @param key カラム名
     */
    updateHeader(key:string):Set<string> {
        this._header.add(key);
        return this._header;
    }

    getHeader() {
        return this._header;
    }

    hasKeyInLine(key:string):boolean {
        return !!this._table[this._coordinate[0]]?.has(key);
    }

    setX(num:number) {
        this._coordinate[0] = num;
    }

    setY(num:number) {
        this._coordinate[1] = num;
    }

    /**
     * 現在のセルにデータを入力し、X方向に移動する。
     * @param key カラム名
     * @param val 入力値
     */
    next(key:string, val:string, force:boolean = false) {
        this.updateHeader(key);
        // __C__ は 直上にCがいるから空白でオーバーライドしてねの意味
        if(!this._table[this._coordinate[1]].has(key) || this._table[this._coordinate[1]].get(key)?.val === '__C__' || force) {
            return this._table[this._coordinate[1]].set(key, {val, x:this._coordinate[0]++, y:this._coordinate[1]});
        }

        return this._coordinate[1]
    }

    /**
     * 現在のセルにデータを入力し、Y方向に移動して先頭のセルを選択する。
     * @param key カラム名
     * @param val 入力値
     */
    line(key: string, val:string) {
        // 行末処理
        this.eol();
        // ヘッダー更新
        this.updateHeader(key);
        // x座標リセット
        this._coordinate[0] = 0;
        const line:Line = new Map();
        line.set(key, {val, x:this._coordinate[0]++, y:++this._coordinate[1]});

        return this._table[this._coordinate[1]] = line;
    }

    /**
     * 行末処理を実施する。
     * Y方向に移動する時点で後ろに空白がある場合は空文字を挿入して空であることを明示する。
     */
    eol() {
        // データの終端で行末処理を呼び出した場合はガード節で止まる
        if(!this._table[this._coordinate[1]].size) return;

        // 反転ヘッダー配列を作成
        let header = [...this._header.values()];
        // 数字式の場合は数字順でソートする（通常は文字扱いされる）
        // console.log('header[0]',header[0],/^\d+$/.test(header[0])); // @DEBUG
        if(/^\d+$/.test(header[0])) {
            header = header.sort((a, b)=>  Number(a) - Number(b));
        } else {
            header = header.sort();
        }
        const headerItr = header[Symbol.iterator]();
        let headerItem = headerItr.next();
        let foundFirstItem = false;
        let coordinateX = 0;
        this._coordinate[0] = 0;
        while(!headerItem.done) {
            if(!this._table[this._coordinate[1]].has(headerItem.value)) {
                // 行がそのkeyの値をもたないとき
                if(foundFirstItem) {
                    // 最初の値が既に見つかっている時
                    this._table[this._coordinate[1]].set(headerItem.value, {val:'', x:coordinateX, y:this._coordinate[1]});
                }
            } else {
                // まだ最初の値が見つかっていない時
                foundFirstItem = true;
            }
            coordinateX++;
            headerItem = headerItr.next();
        }
    }

    /**
     * 判断して入力値を入れる、next()かline()を自動判定する。
     * @param key カラム名
     * @param val 入力値
     */
    autoSet(key: string, val:string) {
        //console.log('autoSet', this._table[this._coordinate[1]], this._header);
        if(this._table[this._coordinate[1]].has(key)) {
            this.line(key, val);
        } else {
            this.next(key, val);
        }
    }

    /**
     * テーブル形式の２次元配列を返す
     */
    generateTableAoa() {
        // 行末処理
        this.eol();

        // ヘッダー生成
        const headerLine = [];
        const headerItr = this._header.values();
        let headerItem = headerItr.next();
        while(!headerItem.done) {
            const key = headerItem.value;
            headerLine.push(key);
            headerItem = headerItr.next();
        }

        // 内容生成
        const tableAoa = this._table.map((row:Line) => {
            const headerTableItr = this._header.values();
            let line = [];
            let result = headerTableItr.next();
            while(!result.done) {
                const key = result.value;
                const val = row.get(key)?.val;
                // __C__ は 直上にCがいるから空白でオーバーライドしてねの意味
                if(val === '__C__') {
                    line.push('');
                } else {
                    line.push(row.get(key)?.val);
                }
                result = headerTableItr.next();
            }
            return line;
        })
        return [headerLine,...tableAoa];
    }

    /**
     * データベース形式の２次元配列を返す
     */
    generateResultAoa() {
        // 行末処理
        this.eol();

        // ヘッダー生成
        const headerLine = [];
        const headerItr = this._header.values();
        let headerItem = headerItr.next();
        while(!headerItem.done) {
            const key = headerItem.value;
            if(key.match(/^\d+[BCDE]$/)) {
                // 1B,2B,3B,3C,3D,3E パターン
                // 文字のオフセットを準備 B,C,D,E
                const charCodeOffsetMap = new Map();
                let charCode = 'B'.charCodeAt(0);
                for(let i=0; i<4; i++) { // i<n Bからn文字分
                    charCodeOffsetMap.set(String.fromCharCode(charCode + i), i);
                }
                const _keyMatch =  key.match(/\w$/);
                const _columnKey = _keyMatch? _keyMatch[0]: false;
                if(charCodeOffsetMap.has(_columnKey)) {
                    const _offset = charCodeOffsetMap.get(_columnKey);
                    headerLine.push(Number(key.match(/\d+/)) + _offset + 'N');
                } else {
                    console.error(_columnKey);
                    throw new Error('変換エラー パターン04 invailed key');
                }
            } else {
                headerLine.push(key.match(/\d+/) + 'N');
            }
            headerLine.push(key);
            headerItem = headerItr.next();
        }

        // 内容生成
        let previousLine:(string|undefined)[] = [];
        const resultAoa = this._table.map((row:Line, index:number) => {
            const headerResultItr = this._header.values();
            let line:(string|undefined)[] = [];
            let result = headerResultItr.next();
            let counter = 0;
            while(!result.done) {
                const key = result.value;

                // 採番出力
                line.push(String(index+1));

                // データ出力
                if(row.has(key)) {
                    // 該当カラムが存在する場合
                    const cell = row.get(key);
                    if(cell && cell.val) {
                        if(cell.val === '__C__') {
                            // __C__ は 直上にCがいるから空白でオーバーライドしてねの意味
                            line.push('');
                        } else {
                            // 通常の値をセット
                            line.push(cell.val);
                        }
                    } else {
                        // 該当カラムが存在しない時
                        line.push('');
                    }
                } else {
                    // 該当カラムが存在しない場合
                    // 前行からコピーしてくる
                    // 行末処理で空文字が入っていれば空文字で上書きされる
                    line.push(previousLine[counter * 2 + 1]);
                }
                result = headerResultItr.next();
                counter++;
            };
            previousLine = line;
            return line;
        })
        return [headerLine,...resultAoa];
    }
}
